export enum AppointmentForm {
    NAME = 'entry.1923832140',
    PHONE = 'entry.1915347105',
    EMAIL = 'entry.1022857706',
    CATEGORY = 'entry.220903961',
    MESSAGE = 'entry.1215987258',
    CREATED_TIMESTAMP = 'entry.1149855464',
}

export interface AppointmentFormData {
    [AppointmentForm.NAME]: string;
    [AppointmentForm.PHONE]: string;
    [AppointmentForm.EMAIL]: string;
    [AppointmentForm.CATEGORY]: string;
    [AppointmentForm.MESSAGE]: string;
    [AppointmentForm.CREATED_TIMESTAMP]: string;
}

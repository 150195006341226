import { AxiosRequestConfig } from "axios";

declare global {
  interface Window {
    Chart: any;
  }
}

export const docReady = (fn: any) => {
  // see if DOM is already available
  if (document.readyState === "loading") {
    document.addEventListener("DOMContentLoaded", fn);
  } else {
    setTimeout(fn, 1);
  }
};

export const resize = (fn: any) => {
  return window.addEventListener("resize", fn);
};

export const isIterableArray = (array: any) => {
  return Array.isArray(array) && !!array.length;
};

export const camelize = (str: string) => {
  var text = str.replace(/[-_\s.]+(.)?/g, (_, c) => {
    return c ? c.toUpperCase() : "";
  });
  return "".concat(text.substr(0, 1).toLowerCase()).concat(text.substr(1));
};

export const getData = (el: any, data: any) => {
  try {
    return JSON.parse(el.dataset[camelize(data)]);
  } catch (e) {
    return el.dataset[camelize(data)];
  }
};

export const hasClass = (el: HTMLElement, className: string) => {
  if (!el) return false;
  return el.classList.value.includes(className);
};

export const addClass = (el: HTMLElement, className: string) => {
  el.classList.add(className);
};

export const hexToRgb = (hexValue: string) => {
  let hex;
  hexValue.indexOf("#") === 0
    ? (hex = hexValue.substring(1))
    : (hex = hexValue); // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")

  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
    hex.replace(shorthandRegex, (m: any, r: any, g: any, b: any) => {
      return r + r + g + g + b + b;
    })
  );
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
      ]
    : [];
};

export const rgbaColor = function rgbaColor() {
  const color =
    arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "#fff";
  const alpha =
    arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0.5;
  return "rgba(".concat(hexToRgb(color).toString(), ", ").concat(alpha, ")");
};
/* --------------------------------- Colors --------------------------------- */

export const colors = {
  primary: "#0091e9",
  secondary: "#002147",
  success: "#00d27a",
  info: "#27bcfd",
  warning: "#FFC928",
  danger: "#EE4D47",
  light: "#F9FAFD",
  dark: "#000",
};

export const grays = {
  white: "#fff",
  100: "#f9fafd",
  200: "#edf2f9",
  300: "#d8e2ef",
  400: "#b6c1d2",
  500: "#9da9bb",
  600: "#748194",
  700: "#5e6e82",
  800: "#4d5969",
  900: "#344050",
  1000: "#232e3c",
  1100: "#0b1727",
  black: "#000",
};

export const getOffset = (el: any) => {
  const rect = el.getBoundingClientRect();
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return {
    top: rect.top + scrollTop,
    left: rect.left + scrollLeft,
  };
};

export const isScrolledIntoView = (el: any) => {
  const width = el.offsetWidth;
  const height = el.offsetHeight;
  let top = el.offsetTop;
  let left = el.offsetLeft;

  while (el.offsetParent) {
    // eslint-disable-next-line no-param-reassign
    el = el.offsetParent;
    top += el.offsetTop;
    left += el.offsetLeft;
  }

  return {
    all:
      top >= window.pageYOffset &&
      left >= window.pageXOffset &&
      top + height <= window.pageYOffset + window.innerHeight &&
      left + width <= window.pageXOffset + window.innerWidth,
    partial:
      top < window.pageYOffset + window.innerHeight &&
      left < window.pageXOffset + window.innerWidth &&
      top + height > window.pageYOffset &&
      left + width > window.pageXOffset,
  };
};

export const breakpoints: { [key: string]: number } = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1540,
};

export const getBreakpoint = (el: Element | undefined) => {
  const classes = el ? el.classList.value : "";
  let breakpoint;

  if (classes) {
    const breakpointClass =
      classes
        .split(" ")
        .filter((cls) => cls.includes("navbar-expand-"))
        .pop()
        ?.split("-")
        .pop() || "";
    breakpoint = breakpoints[breakpointClass];
  }

  return breakpoint || 0;
};

export const setCookie = (name: string, value: string, expire: number) => {
  var expires = new Date();
  expires.setTime(expires.getTime() + expire);
  document.cookie = ""
    .concat(name, "=")
    .concat(value, ";expires=")
    .concat(expires.toUTCString());
};

export const getCookie = (name: string) => {
  const keyValue = document.cookie.match(
    "(^|;) ?".concat(name, "=([^;]*)(;|$)")
  );
  return keyValue ? keyValue[2] : keyValue;
};

export const settings = {
  tinymce: {
    theme: "oxide",
  },
  chart: {
    borderColor: "rgba(255, 255, 255, 0.8)",
  },
};

export const newChart = (chart: any, config: any) => {
  const ctx = chart.getContext("2d");
  return new window.Chart(ctx, config);
};

export const getItemFromStore = (
  key: string,
  defaultValue: string,
  storage: any
) => {
  const store = storage ? storage : localStorage;
  try {
    return JSON.parse(store.getItem(key)) || defaultValue;
  } catch (_unused) {
    return store.getItem(key) || defaultValue;
  }
};

export const setItemToStore = (key: string, payload: string, storage: any) => {
  const store = storage ? storage : localStorage;
  return store.setItem(key, payload);
};

export const getStoreSpace = (storage: any) => {
  const store = storage ? storage : localStorage;
  return parseFloat(
    (
      escape(encodeURIComponent(JSON.stringify(store))).length /
      (1024 * 1024)
    ).toFixed(2)
  );
};

export const appointmentApiConfig: AxiosRequestConfig = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
}

export const appointmentUrl = 'https://docs.google.com/forms/u/0/d/e/1FAIpQLSceC7-OK6P76H5vg_qBrx2Lreb_aS1gkxxMJsrU65taNsXeGQ/formResponse';
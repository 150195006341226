import React, { useEffect } from "react";
import {
  colors,
  getBreakpoint,
  getData,
  grays,
  hasClass,
  hexToRgb,
  resize,
} from "../utils";
import { MobileProps } from "../domain";

enum Selector {
  NAVBAR = "[data-navbar-on-scroll]",
  NAVBAR_COLLAPSE = ".navbar-collapse",
  NAVBAR_TOGGLER = ".navbar-toggler",
}

enum ClassNames {
  COLLAPSED = "collapsed",
}

enum Events {
  SCROLL = "scroll",
  SHOW_BS_COLLAPSE = "show.bs.collapse",
  HIDE_BS_COLLAPSE = "hide.bs.collapse",
  HIDDEN_BS_COLLAPSE = "hidden.bs.collapse",
}

enum DataKey {
  NAVBAR_ON_SCROLL = "navbar-light-on-scroll",
}

export const Navbar: React.FunctionComponent<MobileProps> = ({isMobile}) => {
  useEffect(() => {
    initNavbar();
  }, []);

  const initNavbar = () => {
    const navbar = document.querySelector(Selector.NAVBAR) as HTMLElement;
    navbar?.addEventListener("click", (e) => {
      const target = e.target as Element;
      if (
        target.classList.contains("nav-link") &&
        window.innerWidth < getBreakpoint(navbar)
      ) {
        (navbar.querySelector(Selector.NAVBAR_TOGGLER) as HTMLElement).click();
      }
    });

    if (navbar) {
      var windowHeight = window.innerHeight;
      var html = document.documentElement;
      var navbarCollapse = navbar.querySelector(
        Selector.NAVBAR_COLLAPSE
      ) as HTMLElement;

      var allColors: { [key: string | number]: string } = {
        ...colors,
        ...grays,
      };

      var name = getData(navbar, DataKey.NAVBAR_ON_SCROLL);
      var colorName = Object.keys(allColors).includes(name) ? name : "light";
      var color = allColors[colorName];
      var bgClassName = "bg-".concat(colorName);
      var shadowName = "shadow-transition";
      var colorRgb = hexToRgb(color);

      var _window$getComputedSt = window.getComputedStyle(navbar),
        backgroundImage = _window$getComputedSt.backgroundImage;

      var transition = "background-color 0.35s ease";
      navbar.style.backgroundImage = "none"; // Change navbar background color on scroll

      window.addEventListener(Events.SCROLL, function () {
        var scrollTop = html.scrollTop;
        var alpha = (scrollTop / windowHeight) * 0.35; // Add class on scroll

        navbar.classList.add("backdrop");

        if (alpha === 0) {
          navbar.classList.remove("backdrop");
        }

        alpha >= 1 && (alpha = 1);
        navbar.style.backgroundColor = "rgba("
          .concat(colorRgb[0].toString(), ", ")
          .concat(colorRgb[1].toString(), ", ")
          .concat(colorRgb[2].toString(), ", ")
          .concat(alpha.toString(), ")");
        navbar.style.backgroundImage =
          alpha > 0 || hasClass(navbarCollapse, "show")
            ? backgroundImage
            : "none";
        alpha > 0 || hasClass(navbarCollapse, "show")
          ? navbar.classList.add(shadowName)
          : navbar.classList.remove(shadowName);
      }); // Toggle bg class on window resize

      resize(function () {
        var breakPoint = getBreakpoint(navbar);

        if (window.innerWidth > breakPoint) {
          navbar.style.backgroundImage = html.scrollTop
            ? backgroundImage
            : "none";
          navbar.style.transition = "none";
        } else if (
          !hasClass(
            navbar.querySelector(Selector.NAVBAR_TOGGLER) as HTMLElement,
            ClassNames.COLLAPSED
          )
        ) {
          navbar.classList.add(bgClassName);
          navbar.classList.add(shadowName);
          navbar.style.backgroundImage = backgroundImage;
        }

        if (window.innerWidth <= breakPoint) {
          navbar.style.transition = hasClass(navbarCollapse, "show")
            ? transition
            : "none";
        }
      });
      navbarCollapse.addEventListener(Events.SHOW_BS_COLLAPSE, function () {
        navbar.classList.add(bgClassName);
        navbar.classList.add(shadowName);
        navbar.style.backgroundImage = backgroundImage;
        navbar.style.transition = transition;
      });
      navbarCollapse.addEventListener(Events.HIDE_BS_COLLAPSE, function () {
        navbar.classList.remove(bgClassName);
        navbar.classList.remove(shadowName);
        !html.scrollTop && (navbar.style.backgroundImage = "none");
      });
      navbarCollapse.addEventListener(Events.HIDDEN_BS_COLLAPSE, function () {
        navbar.style.transition = "none";
      });
    }
  };

  return (
    <>
      <nav
        className="navbar navbar-expand-lg navbar-light fixed-top py-3 d-block"
        data-navbar-on-scroll="data-navbar-on-scroll"
      >
        <div className="container">
          <a className={`navbar-brand ${isMobile ? 'me-0' : ''}`} href="index.html">
            <img src="assets/img/gallery/logo.png" width={isMobile ? "40" : "100"} alt="logo" />
            <span className={`lh-lg fw-bold ${isMobile ? 'ms-3 fs-1' : 'ms-4'} text-secondary font-sans-serif`}>
              AGGARWAL DENTAL CARE
            </span>
          </a>
          <button
            className={`navbar-toggler ${isMobile ? 'px-2' : ''}`}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"> </span>
          </button>
          <div
            className="collapse navbar-collapse border-top border-lg-0 mt-4 mt-lg-0"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav ms-auto pt-2 pt-lg-0 font-base">
              <li className="nav-item px-2">
                <a className="nav-link" aria-current="page" href="#about">
                  About Us
                </a>
              </li>
              <li className="nav-item px-2">
                <a className="nav-link" href="#services">
                  Services
                </a>
              </li>
              <li className="nav-item px-2">
                <a className="nav-link" href="#technology">
                  Technology
                </a>
              </li>
              <li className="nav-item px-2">
                <a className="nav-link" href="#contactUs">
                  Contact
                </a>
              </li>
            </ul>
            <a
              className={`order-1 order-lg-0 ${isMobile ? 'ms-2' : 'ms-4'}`}
              href="https://wa.link/exytco"
              target="__blank"
            >
              <svg className="mt-2" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24">
                <path fill="#30A64A" d="M12.036 5.339c-3.635 0-6.591 2.956-6.593 6.589-.001 1.483.434 2.594 1.164 3.756l-.666 2.432 2.494-.654c1.117.663 2.184 1.061 3.595 1.061 3.632 0 6.591-2.956 6.592-6.59.003-3.641-2.942-6.593-6.586-6.594zm3.876 9.423c-.165.463-.957.885-1.337.942-.341.051-.773.072-1.248-.078-.288-.091-.657-.213-1.129-.417-1.987-.858-3.285-2.859-3.384-2.991-.099-.132-.809-1.074-.809-2.049 0-.975.512-1.454.693-1.653.182-.2.396-.25.528-.25l.38.007c.122.006.285-.046.446.34.165.397.561 1.372.611 1.471.049.099.083.215.016.347-.066.132-.099.215-.198.33l-.297.347c-.099.099-.202.206-.087.404.116.198.513.847 1.102 1.372.757.675 1.395.884 1.593.983.198.099.314.083.429-.05.116-.132.495-.578.627-.777s.264-.165.446-.099 1.156.545 1.354.645c.198.099.33.149.38.231.049.085.049.482-.116.945zm3.088-14.762h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-6.967 19.862c-1.327 0-2.634-.333-3.792-.965l-4.203 1.103 1.125-4.108c-.694-1.202-1.059-2.566-1.058-3.964.002-4.372 3.558-7.928 7.928-7.928 2.121.001 4.112.827 5.609 2.325s2.321 3.491 2.32 5.609c-.002 4.372-3.559 7.928-7.929 7.928z"/>
              </svg>
            </a>
          </div>
        </div>
      </nav>
    </>
  );
};

import axios from "axios";
import { useRef, useState } from "react";
import { DentalServicesData } from "../data";
import { AppointmentForm, AppointmentFormData, MobileProps } from "../domain";
import { appointmentApiConfig, appointmentUrl } from "../utils";

export const Appointment: React.FunctionComponent<MobileProps> = ({isMobile}) => {
    const appointmentForm = useRef(null);
    const [loading, setLoader] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [category, setCategory] = useState("");
    const [message, setMessage] = useState("");

    const submitAppointmentForm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoader(true);
        const data: AppointmentFormData = {
            [AppointmentForm.NAME]: name,
            [AppointmentForm.PHONE]: phone,
            [AppointmentForm.EMAIL]: email,
            [AppointmentForm.CATEGORY]: category,
            [AppointmentForm.MESSAGE]: message,
            [AppointmentForm.CREATED_TIMESTAMP]: new Date().toString(),
        };
        // @ts-ignore
        appointmentForm.current.reset();
        sendAppointmentRequest(data);
    };

    const resetAppointmentForm = async () => {
        console.log('resetting form ->>>>', appointmentForm.current);
        // @ts-ignore
        appointmentForm.current.reset();
    };

    const sendAppointmentRequest = async (data: AppointmentFormData) => {
        try {
            await axios.post(appointmentUrl, data, appointmentApiConfig);
        } catch (error) {
        } finally {
            setLoader(false);
            resetAppointmentForm();
        }
    };

    return (
        <>
            <section className={`${isMobile ? "py-0" : "py-5"}`} id="appointments">
                <div className="container">
                    <div className="row">
                        <div className="col-12 py-3">
                            <div
                                className="bg-holder bg-size"
                                style={{
                                    backgroundImage: "url(assets/img/gallery/appointment-background.png)",
                                    backgroundPosition: "top center",
                                    backgroundSize: "contain",
                                }}
                            ></div>
                            <h1 className="text-center">APPOINTMENT</h1>
                        </div>
                    </div>
                </div>
            </section>
            <section className={`${isMobile ? "py-4" : "py-6"}`}>
                <div className="container">
                    <div className="row">
                        <div
                            className="bg-holder bg-size"
                            style={{
                                backgroundImage: "url(assets/img/gallery/dot-bg.png)",
                                backgroundPosition: "bottom right",
                                backgroundSize: "auto",
                            }}
                        ></div>

                        <div className="col-lg-6 z-index-2 mb-5">
                            <img
                                className="w-100"
                                src="assets/img/gallery/appointment.png"
                                alt="..."
                            />
                        </div>
                        <div className="col-lg-6 z-index-2">
                            <form ref={appointmentForm} className="row g-3" onSubmit={submitAppointmentForm}>
                                <input type="hidden" name="dlut" value="1679853722890" />
                                <div className="col-md-6">
                                    <label className="visually-hidden" htmlFor="inputName">
                                        Name
                                    </label>
                                    <input
                                        className="form-control form-livedoc-control"
                                        id="inputName"
                                        type="text"
                                        name="entry.1115520803"
                                        placeholder="Name"
                                        onChange={(e) => setName(e.target.value)}
                                        disabled={loading ? true : false}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label className="visually-hidden" htmlFor="inputPhone">
                                        Phone
                                    </label>
                                    <input
                                        className="form-control form-livedoc-control"
                                        id="inputPhone"
                                        type="text"
                                        name="entry.1438833382"
                                        placeholder="Phone"
                                        onChange={(e) => setPhone(e.target.value)}
                                        disabled={loading ? true : false}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label
                                        className="form-label visually-hidden"
                                        htmlFor="inputCategory"
                                    >
                                        Category
                                    </label>
                                    <select
                                        className="form-select"
                                        id="inputCategory"
                                        onChange={(event) => setCategory(event.target.value)}
                                        disabled={loading ? true : false}
                                    >
                                        <option value={""}>Category</option>
                                        {DentalServicesData.map((service, index) => {
                                            return (
                                                <option
                                                    key={`${index}-appointment-${service.name}`}
                                                    value={service.name}
                                                >
                                                    {service.name}
                                                </option>
                                            );
                                        })}
                                        <option value={"Others"}>Others</option>
                                    </select>
                                </div>
                                <div className="col-md-6">
                                    <label
                                        className="form-label visually-hidden"
                                        htmlFor="inputEmail"
                                    >
                                        Email
                                    </label>
                                    <input
                                        className="form-control form-livedoc-control"
                                        id="inputEmail"
                                        type="email"
                                        name="entry.1399865285"
                                        placeholder="Email"
                                        defaultValue={email}
                                        disabled={loading ? true : false}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                                <div className="col-md-12">
                                    <label
                                        className="form-label visually-hidden"
                                        htmlFor="validationTextarea"
                                    >
                                        Message
                                    </label>
                                    <textarea
                                        className="form-control form-livedoc-control"
                                        id="validationTextarea"
                                        placeholder="Message"
                                        style={{
                                            height: "250px",
                                        }}
                                        name="entry.1043600057"
                                        required={true}
                                        onChange={(e) => setMessage(e.target.value)}
                                        disabled={loading ? true : false}
                                        defaultValue={message}
                                    ></textarea>
                                </div>
                                <div className="col-12">
                                    <div className="d-grid">
                                        <button
                                            className="btn btn-primary rounded-pill"
                                            type="submit"
                                            disabled={loading ? true : false}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
